// Normalize Styles
@import 'node_modules/normalize.css/normalize';

// Import Modules
//@import '../_modules/link/link';

@import "node_modules/bulma/bulma";

/*
@import "bulma/utilities/utilities";
@import "bulma/config/variables";
@import "bulma/config/generated-variables";
@import "bulma/base/base";
@import "bulma/elements/elements";
@import "bulma/components/components";
@import "bulma/layout/layout";
*/

@import "node_modules/font-awesome/scss/font-awesome.scss";
$fa-font-path: "../fonts";


// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}


body, html {
	font-family: 'Roboto', sans-serif;
}

.has-text-centered {
	text-align: center;
}


.hero.is-info {
	//background-image: linear-gradient(163deg,#1b2128,#61758e);
	background-image: linear-gradient(163deg,darken(#42afe3, 20%),#42afe3);

	position: relative;

	.hero-content {

		h1.title {
			font-weight: 400;
		}
		h2.subtitle {
			color: rgba(255, 255, 255, 0.9);
		}
	}

	.hero-footer {
		.tabs li {
			&:not(.is-active) {
				a {
					text-shadow: 0px 1px 5px rgba(48, 70, 95, 0.37);
					opacity: 1;
				}
			}
		}
	}
}

.card {
	box-shadow: none;

	.card-image {
		text-align: center;

		.icon {
			font-size: 100px;
			display: initial;
			line-height: normal;
		}
	}

	.card-content {
		text-align: center;
		padding-top: 0;
	}
}

section {
	h1.title {
		color: rgb(60, 65, 70);
		font-size: 38px;
		font-weight: 300;
		line-height: 43px;
		margin-bottom: 10px;
		text-transform: none;
		letter-spacing: normal;
	}

	h2.subtitle {
		font-size: 26px;
		font-style: normal;
		font-variant: normal;
		font-weight: 300;
		line-height: 36.4px;
		text-transform: none;
		letter-spacing: normal;
	}

	&.fade-gradient {
		background-image: linear-gradient(180deg, #f5f5f5, #fff);
		background-repeat: no-repeat;
		background-size: 100% 200px;
		border-top: none !important;
	}

	&.no-border {
		border-top: none !important;
	}

	.container.split {
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;

		&.mobile-reverse {
			flex-direction: column-reverse;
		}

		@media (min-width: 769px) {
			flex-direction: row;

			&.mobile-reverse {
				flex-direction: row;
			}
		}

		.content {
			margin-bottom: 0 !important;
		}

		& > div {
			width: 100%;
			padding: 40px 20px;

			@media (min-width: 769px) {
				width: 50%;
			}

			p, li {
				font-size: 16px;
			}

			ul {
				margin-bottom: 0;
			}

			h2 {
				margin-bottom: 0;
			}
		}
	}
}
